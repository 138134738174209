import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";
import { auth } from "../firebaseConfig";
import { signInWithEmailAndPassword } from "firebase/auth";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const toast = useToast();
  const navigate = useNavigate();
  const bgColor = useColorModeValue('gray.100', 'gray.900');
  const textColor = useColorModeValue("black", "white");

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const idToken = await userCredential.user.getIdToken(); // Retrieve the ID token

      // Send the ID token to your Flask backend in the Authorization header
      const response = await fetch("/api/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`, // Send the token in the Authorization header
        },
      });

      const data = await response.json();
      if (response.ok) {
        // Store the token in local storage
        localStorage.setItem("token", idToken);

        // Optionally store other user info
        localStorage.setItem("userEmail", email);
        localStorage.setItem("fullName", data.fullName);

        // Redirect to dashboard after successful login
        navigate("/dashboard");
      } else {
        toast({
          title: "Login Failed",
          description: data.message || "An error occurred",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Login Failed",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      p="6"
      borderRadius="lg"
      bg={bgColor}
      boxShadow="lg"
      color={textColor}
      minHeight="100vh"
    >
      <Text fontSize="2xl" mb="6">
        Login
      </Text>
      <form onSubmit={handleLogin}>
        <FormControl mb="4" isRequired>
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            bg={useColorModeValue("gray.100", "gray.700")}
          />
        </FormControl>
        <FormControl mb="4" isRequired>
          <FormLabel>Password</FormLabel>
          <Input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter your password"
            bg={useColorModeValue("gray.100", "gray.700")}
          />
        </FormControl>
        <Button type="submit" colorScheme="teal" width="full">
          Login
        </Button>
      </form>
      <Button
        mt="4"
        onClick={() => navigate("/signup")}
        colorScheme="blue"
        width="full"
      >
        Don't have an account? Sign Up
      </Button>
    </Box>
  );
};

export default Login;
