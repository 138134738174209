import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyA5qWowg5XlL4J7gHxeBb1oSa2dM16MjyM",
  authDomain: "captest-5dcdb.firebaseapp.com",
  projectId: "captest-5dcdb",
  storageBucket: "captest-5dcdb.appspot.com",
  messagingSenderId: "885002721489",
  appId: "1:885002721489:web:c761f07be22694178d8df0",
  measurementId: "G-0SPVFBQYHS",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
