import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Heading,
  Button,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebaseConfig"; // Ensure you import your Firebase auth instance
import { onAuthStateChanged } from "firebase/auth"; // Import Firebase auth state change listener
import CreateProfile from "./CreateProfile"; // Import your CreateProfile component
import ManageProfiles from "./ManageProfiles"; // Import your ManageProfiles component
import CheckSignature from "./CheckSignature"; // Import your CheckSignature component
import CheckDocument from "./CheckDocument"; // Import your new CheckDocument component

const Dashboard = () => {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("home"); // State to track the selected tab
  const [fullName, setFullName] = useState("");

  useEffect(() => {
    // Fetch the full name from local storage when the component mounts
    const name = localStorage.getItem("fullName");
    if (name) {
      setFullName(name);
    }

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate("/login"); // Redirect to login if not authenticated
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [navigate]);

  const handleLogout = async () => {
    await auth.signOut(); // Sign out using Firebase
    navigate("/"); // Redirect to the landing page after logout
  };

  // Function to render the selected tab content
  const renderTabContent = () => {
    switch (selectedTab) {
      case "create":
        return <CreateProfile />;
      case "manage":
        return <ManageProfiles />;
      case "check":
        return <CheckSignature />;
      case "check-document": // New case for the "Check Document" tab
        return <CheckDocument />;
      default:
        return <Text>Please select a tab to see the content.</Text>;
    }
  };

  return (
    <Flex
      height="75vh"
      direction="column" // Stack elements vertically
      bg={useColorModeValue("gray.100", "gray.900")}
    >
      {/* Header */}
      <Flex
        justify="space-between"
        align="center"
        p="4"
        bg={useColorModeValue("blue.400", "blue.900")}
        color="white"
      >
        <Heading size="lg" >Dashboard</Heading>
        <Button colorScheme="red" onClick={handleLogout}>
          Logout
        </Button>
      </Flex>

      <Flex flex="1" overflow="hidden" direction={{ base: "column", md: "row" }}>
        {/* Sidebar */}
        <Box
          width={{ base: "100%", md: "250px" }} // Full width on small screens, fixed width on larger screens
          bg={useColorModeValue('gray.450', 'gray.750')}
          boxShadow="md"
          borderRadius="lg"
          p="4"
          overflowY="auto"
          mb={{ base: "4", md: "0" }} // Add bottom margin for mobile spacing
        >
          <Heading size="md" mb="4" color={useColorModeValue("black", "white")}>
          Welcome, {fullName || "User!"}
          </Heading>
          <Button
            variant="outline"
            width="100%"
            mb="2"
            onClick={() => setSelectedTab("create")}
          >
            Create Signature Profile
          </Button>
          <Button
            variant="outline"
            width="100%"
            mb="2"
            onClick={() => setSelectedTab("manage")}
          >
            Manage Profiles
          </Button>
          <Button
            variant="outline"
            width="100%"
            mb="2"
            onClick={() => setSelectedTab("check")}
          >
            Check Signature
          </Button>
{/* 
          <Button
            variant="outline"
            width="100%"
            mb="2"
            onClick={() => setSelectedTab("check-document")} // New button for "Check Document"
          >
            Check Document
          </Button>
           */}
        </Box>

        {/* Main Content Area */}
        <Box
          flex="1"
          p="4"
          overflowY="auto"
          borderRadius="lg"
          bg={useColorModeValue("gray.50", "gray.800")}
          boxShadow="md"
        >
          {renderTabContent()}
        </Box>
      </Flex>
    </Flex>
  );
};

export default Dashboard;
