import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Select,
  Text,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";

const CheckDocument = () => {
  const [file, setFile] = useState(null);
  const [selectedProfile, setSelectedProfile] = useState("");
  const [profiles, setProfiles] = useState([]);
  const toast = useToast();
  const bgColor = useColorModeValue("gray.100", "gray.700");
  const textColor = useColorModeValue("black", "white");

  useEffect(() => {
    const fetchProfiles = async () => {
      try {
        const token = localStorage.getItem("token"); // Retrieve the token
        const response = await fetch("/api/profiles", {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
          },
        });

        if (response.ok) {
          const data = await response.json();
          // Assume data is an array of profile names
          setProfiles(data); // Directly set the array of profile names
        } else {
          toast({
            title: "Error",
            description: "Failed to load profiles.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        toast({
          title: "Error",
          description: "An error occurred while fetching profiles.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    };

    fetchProfiles();
  }, [toast]);

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const allowedTypes = ["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
    if (file && allowedTypes.includes(file.type)) {
      setFile(file);
    } else {
      toast({
        title: "Invalid File Type",
        description: "Please upload a PDF or Word document.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!file || !selectedProfile) {
      toast({
        title: "Missing Fields",
        description: "Please select a profile and upload a document.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("profile", selectedProfile);

    try {
      const token = localStorage.getItem("token"); // Retrieve the token
      const response = await fetch("/api/check_document", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        },
        body: formData,
      });
      const result = await response.json();

      if (!response.ok) throw new Error(result.message);

      toast({
        title: "Document Checked",
        description: result.message,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error checking document:", error);
      toast({
        title: "Error",
        description: error.message || "An error occurred while checking the document.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setFile(null);
      setSelectedProfile("");
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [],
      "application/msword": [],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [],
    },
  });

  return (
    <Box p="4" borderRadius="lg" bg={bgColor} boxShadow="md" color={textColor} maxHeight="90vh">
      <Text fontSize="xl" mb="4">Check Document</Text>
      <form onSubmit={handleSubmit}>
        <FormControl mb="4">
          <FormLabel>Select Profile</FormLabel>
          <Select
            placeholder="Select profile"
            value={selectedProfile}
            onChange={(e) => setSelectedProfile(e.target.value)}
            required
            bg={useColorModeValue("gray.300", "gray.700")}
          >
            {profiles.map((profile, index) => (
              <option key={index} value={profile}>{profile}</option> // Use profile name directly
            ))}
          </Select>
        </FormControl>
        <FormControl mb="4">
          <FormLabel>Upload Document (PDF or Word)</FormLabel>
          <Box
            {...getRootProps()}
            borderWidth={2}
            borderStyle="dashed"
            borderColor="gray.300"
            borderRadius="md"
            p={4}
            bg={useColorModeValue("gray.300", "gray.700")}
            textAlign="center"
            color={textColor}
          >
            <input {...getInputProps()} />
            {file ? (
              <Text>{file.name}</Text>
            ) : (
              <Text>Drag & drop a document here, or click to select one</Text>
            )}
          </Box>
        </FormControl>
        <Button type="submit" colorScheme="teal" width="full">Check Document</Button>
      </form>
    </Box>
  );
};

export default CheckDocument;
