import React, { useEffect } from 'react';
import { Box, Switch, useColorMode, HStack } from '@chakra-ui/react';
import { SunIcon, MoonIcon } from '@chakra-ui/icons';
import logoLight from './Assets/DocuSafeLogo.png'; // Adjust the path to your light mode logo
import logoDark from './Assets/DocuSafe.png'; // Adjust the path to your dark mode logo

const Layout = ({ children }) => {
  const { colorMode, toggleColorMode, setColorMode } = useColorMode();

  // Set the initial color mode to dark mode if not already set
  useEffect(() => {
    const storedColorMode = localStorage.getItem('chakra-ui-color-mode');
    if (!storedColorMode) {
      setColorMode('dark');
    }
  }, [setColorMode]);

  // Select the logo based on the current color mode
  const logo = colorMode === 'light' ? logoLight : logoDark;

  return (
    <Box
      minHeight="100vh"
      className={colorMode === 'light' ? 'light-mode' : 'dark-mode'}
      p={0}
      borderRadius="20px" // Add rounded edges
      boxShadow="lg" // Add shadow for a softer look
      bg={colorMode === 'light' ? 'gray.200' : 'gray.700'}
    >
      <header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '20px' }}>
        <img src={logo} alt="Logo" style={{ height: '40px' }} /> {/* Use the selected logo */}
        <HStack spacing={3} align="center">
          {colorMode === 'light' ? <SunIcon /> : <MoonIcon />}
          <Switch onChange={toggleColorMode} isChecked={colorMode === 'dark'} />
        </HStack>
      </header>
      <Box mt={4} borderRadius="10px" p={4} bg={colorMode === 'light' ? 'gray.300' : 'gray.800'}>
        {children}
      </Box>
    </Box>
  );
};

export default Layout;
