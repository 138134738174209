import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
  useToast,
  useColorModeValue,
  Image,
  IconButton,
} from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import { CloseIcon } from "@chakra-ui/icons"; // Import CloseIcon for delete button

const CreateProfile = () => {
  const [files, setFiles] = useState([]); // State to hold an array of files
  const [name, setName] = useState("");
  const toast = useToast();
  const bgColor = useColorModeValue("gray.100", "gray.700");
  const textColor = useColorModeValue("black", "white");

  const onDrop = (acceptedFiles) => {
    const newFiles = acceptedFiles.filter(file => file.type.startsWith("image/")); // Filter valid image files
    if (newFiles.length) {
      setFiles((prevFiles) => [...prevFiles, ...newFiles]); // Add the new files to the existing array
    } else {
      toast({
        title: "Invalid File Type",
        description: "Please upload valid image files.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDelete = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index)); // Remove the selected file
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (files.length < 3 || !name) {
      toast({
        title: "Missing Fields",
        description: "Please provide a name and upload at least three images.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    // Retrieve the token from local storage
    const token = localStorage.getItem("token");

    if (!token) {
      toast({
        title: "Unauthorized",
        description: "Please log in to create a profile.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const formData = new FormData();
    formData.append("profile_name", name); // Match the backend expected key
    files.forEach((file) => formData.append("images", file)); // Append all files

    try {
      const response = await fetch("/api/train_profile", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the request headers
        },
        body: formData, // Send form data without setting Content-Type
      });

      if (response.ok) {
        toast({
          title: "Profile Created",
          description: `Signature profile "${name}" created successfully!`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setFiles([]); // Clear the files
        setName("");
      } else {
        const errorData = await response.json();
        toast({
          title: "Error",
          description: errorData.message || "Failed to create profile.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "There was an issue creating the profile. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { "image/*": [] },
    multiple: true, // Allow multiple file uploads
  });

  return (
    <Box
      p="4"
      borderRadius="lg"
      bg={bgColor}
      boxShadow="lg"
      color={textColor}
      maxHeight="80vh" 
      overflowY="auto" 
    >
      <Text fontSize="xl" mb="4">
        Create Signature Profile
      </Text>
      <form onSubmit={handleSubmit}>
        <FormControl mb="4" isRequired>
          <FormLabel>Profile Name</FormLabel>
          <Input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter profile name"
            bg={useColorModeValue("gray.300", "gray.700")}
          />
        </FormControl>
        <FormControl mb="4" isRequired>
          <FormLabel>Upload Signature Images</FormLabel>
          <Box
            {...getRootProps()}
            borderWidth={2}
            borderStyle="dashed"
            borderColor="gray.300"
            borderRadius="md"
            p={4}
            bg={useColorModeValue("gray.300", "gray.700")}
            textAlign="center"
          >
            <input {...getInputProps()} />
            <Text color={textColor}>
              {files.length > 0
                ? `Selected ${files.length} file(s)`
                : "Drag and drop files, or click to select"}
            </Text>
          </Box>
          {files.length > 0 && (
            <Box mt={2}>
              {files.map((file, index) => (
                <Box key={index} mb={2} display="flex" alignItems="center">
                  <Image
                    src={URL.createObjectURL(file)}
                    alt="Selected Image"
                    maxW="100px"
                    borderRadius="md"
                    mr={2}
                  />
                  <IconButton
                    aria-label="Delete image"
                    icon={<CloseIcon />}
                    onClick={() => handleDelete(index)}
                    colorScheme="red"
                    size="sm"
                  />
                </Box>
              ))}
            </Box>
          )}
        </FormControl>
        <Button type="submit" colorScheme="teal" width="full">
          Create Profile
        </Button>
      </form>
    </Box>
  );
};

export default CreateProfile;
