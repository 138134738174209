// src/components/NotFound.js
import React from 'react';
import { Box, Heading, Text, Button, useColorModeValue } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const NotFound = () => {

  return (
    <Box
      textAlign="center"
      padding="50px"
      bg="gray.100"
      borderRadius="10px"
      boxShadow="lg"
    >
      <Heading as="h1" size="2xl" marginBottom="20px" color='gray.800'>
        404 - Page Not Found
      </Heading>
      <Text fontSize="lg" marginBottom="20px" color='gray.800'>
        Oops! The page you are looking for does not exist.
      </Text>
      <Button colorScheme="teal" as={Link} to="/">
        Go to Home
      </Button>
    </Box>
  );
};

export default NotFound;
